import React from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
const Feedback =()=>{
    return(
        <section>
            <Navbar/>

            <div className="bgmain">
                <div className="container py-5">
                 <h1 className="text-white text-center mb-5">Feedback</h1>

                 <div className="text-center whiteShadow text-white py-3 d-flex justify-content-center align-items-center rounded-pill">
                     <p className="mb-0">For Feedback : Support@kohiclicktest.net</p>
                 </div>
                </div>


            </div>

            <div className="fixed-bottom">
                <Footer/>
            </div>

        </section>
    )
}
export default Feedback;