import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { MetaTags } from "react-meta-tags";


const AboutUs = () => {
    return (
        <section>

            <MetaTags>

                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                <title>About Us | cpstester.io</title>
                <meta name="title" content="About Us | cpstester.io"></meta>
                <meta name="description" content="The goal of the CPS Tester team is to design a website with a user-friendly interface. The idea behind developing CPS Tester is to engage and unite gamers."></meta>


                <meta property="og:type" content="website"></meta>
                <meta property="og:url" content=""></meta>
                <meta property="og:title" content="About Us | cpstester.io"></meta>
                <meta property="og:description" content="The goal of the CPS Tester team is to design a website with a user-friendly interface. The idea behind developing CPS Tester is to engage and unite gamers."></meta>
                <meta property="og:image" content=""></meta>


                <meta property="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:url" content=""></meta>
                <meta property="twitter:title" content="About Us | cpstester.io"></meta>
                <meta property="twitter:description" content="The goal of the CPS Tester team is to design a website with a user-friendly interface. The idea behind developing CPS Tester is to engage and unite gamers."></meta>
                <meta property="twitter:image" content=""></meta>
                <link rel="canonical" href="" />
                <meta name="robots" content="index, follow"></meta>
                <meta name="revisit-after" content="1 days"></meta>
                <meta name="author" content=""></meta>

            </MetaTags>
            <Navbar />
            <div className="py-5 text-white container">
                <h1 className="text-center">ABOUT US</h1>
                <p className="lh-lg text-center mb-4">At Kohi Click Test, we have created a community of people who are keenly interested in gaming and technology. Kohi Click Test has a user-friendly interface providing fast and accurate results to its users. We allow users to take Kohi Click Test and other clicking tests like Jitter Click Test, Butterfly Click Test for various time intervals enabling the users to practice consistently.</p>
                <p className="lh-lg text-center">Taking Kohi Click Test and enhancing users clicking speed is what we aim for. By taking the test, gamers benefit vastly, as Kohi Tests enable them to practice before a PvP match in games like Minecraft and other games. Take our Kohi Test and master it to become a pro at clicking.</p>
            </div>
            <div className="fixed-bottom">
                <Footer />
            </div>


        </section>
    )
}
export default AboutUs
