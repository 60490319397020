import React, { useState, useEffect } from 'react';
import './Kohiclicker.css';
import kohiemoji from '../images/kohi-click-test.png';


import first from '../images/click icon 1.svg';
import sec from '../images/click icon 2.svg';
import thr from '../images/click icon 3.svg';
import MetaTags from 'react-meta-tags';
import Navbar from './Navbar';
import Footer from './Footer';
import butterFly from '../images/Butterfly Clicking.png'
import Drag from '../images/Drag Clicking.png'
import Jitter from '../images/Jitter Clicking.png'
import stepOne from '../images/Step_1.png'
import stepTwo from '../images/Step_2.png'
import stepThree from '../images/Step_3.png'
import stepFour from '../images/Step_4.png'





const KohiClicker = () => {

    let [counter, setCounter] = useState(0)
    let [timeDuration, setTimeDuration] = useState(5)
    let [hiddenInpVal, setHiddenInpVal] = useState("0");
    let [intVStopper, setIntVStopper] = useState("Stop");


    // let [finalScoreVal,setFinalScoreVal]=useState(0);
    let maxScore = parseInt(localStorage.getItem("count"));
    let cps = maxScore / 5;


    useEffect(()=>{
        
    })



    let counterFun = () => {

        setCounter(counter = counter + 1);
        let finalMaxScore = document.getElementById("finalMaxScore");
        finalMaxScore.style.display = "none";
        let timerSpan = document.getElementById("timerSpan");
        timerSpan.style.display = "block";
        let ClickButton = document.getElementById("ClickButton");
        console.log(hiddenInpVal + " hiddenInpVal")
        if (hiddenInpVal == "0") {
            console.log("i am here")
            var interVal = setInterval(() => {
                setTimeDuration(timeDuration = timeDuration - 1);
                // console.log(timeDuration+" timeDuration")
                if (timeDuration == 0) {

                    timerSpan.innerText = `your score is ${document.getElementById("ClickCount").innerText}`;
                    ClickButton.setAttribute("disabled", "")
                    clearInterval(interVal);
                    finalMaxScore.style.display = "block";
                    setTimeDuration(timeDuration = 5)
                    let getCounterData = localStorage.getItem("count");
                    if (getCounterData) {
                        var getInt = parseInt(getCounterData);
                        if (getInt < document.getElementById("ClickCount").innerText) {
                            localStorage.setItem("count", document.getElementById("ClickCount").innerText);
                            console.log(maxScore + " maxscore");
                            let data = parseInt(localStorage.getItem("count"));
                            let cpsData = data / 5;
                            finalMaxScore.innerText = `YOUR MAX SCORE: ${data} (${cpsData} CPS)`

                        }

                    } else {

                        localStorage.setItem("count", document.getElementById("ClickCount").innerText);
                        finalMaxScore.innerText = `YOUR MAX SCORE: ${maxScore} (${cps} CPS)`

                    }


                }
                let StopClicks = document.getElementById("StopClicks");
                console.log(StopClicks);
                StopClicks.addEventListener("click", () => {

                    if (intVStopper == "Stop") {
                        console.log("i am here moron")
                        clearInterval(interVal);
                        ClickButton.style.boxShadow = "0px 0px 5px red";
                        setIntVStopper(intVStopper = "Start");
                        ClickButton.setAttribute("disabled", "")
                        var StopTime = timeDuration;
                        setTimeDuration(timeDuration = StopTime);


                    } else {
                        setIntVStopper(intVStopper = "Stop");

                        console.log("i am here moron 2")


                        ClickButton.style.boxShadow = "0px 0px 5px white";


                        ClickButton.removeAttribute("disabled");
                        let stopInterVal = setInterval(() => {
                            setTimeDuration(timeDuration = timeDuration - 1);
                            if (timeDuration == 0) {
                                timerSpan.innerText = `your score is ${document.getElementById("ClickCount").innerText}`;
                                ClickButton.setAttribute("disabled", "")
                                clearInterval(stopInterVal);
                                finalMaxScore.style.display = "block";
                                setTimeDuration(timeDuration = 5)
                                let getCounterData = localStorage.getItem("count");
                                if (getCounterData) {
                                    var getInt = parseInt(getCounterData);
                                    if (getInt < document.getElementById("ClickCount").innerText) {
                                        localStorage.setItem("count", document.getElementById("ClickCount").innerText);
                                        console.log(maxScore + " maxscore");
                                        let data = parseInt(localStorage.getItem("count"));
                                        let cpsData = data / 5;
                                        finalMaxScore.innerText = `YOUR MAX SCORE: ${data} (${cpsData} CPS)`

                                    }

                                } else {

                                    localStorage.setItem("count", document.getElementById("ClickCount").innerText);
                                    finalMaxScore.innerText = `YOUR MAX SCORE: ${maxScore} (${cps} CPS)`

                                }


                            }

                        }, 1000)


                    }

                })
                //    StopClicks.addEventListener("click",()=>{
                //     console.log("clicks");
                // })

            }, 1000)

            setHiddenInpVal(hiddenInpVal = "1");


        }


    }

    let restartFun = () => {
        window.location.reload();
        // let timerSpan= document.getElementById("timerSpan");
        // timerSpan.style.display="none";
        //  timerSpan.innerHTML=`👊 Time to the end: ${timeDuration} sec.`
        // let finalMaxScore= document.getElementById("finalMaxScore");
        // finalMaxScore.style.display="block";

        // setCounter(counter=0);
        // let ClickButton= document.getElementById("ClickButton");
        // ClickButton.removeAttribute("disabled");
        // setHiddenInpVal(hiddenInpVal="0");



    }

    let resetMaxScore = () => {
        localStorage.setItem("count", "0");
        window.location.reload();
    }


    return (
        <section>
            <MetaTags>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                <title>Kohi Click Test | Kohiclicktest.net</title>
                <meta name="title" content="Kohi Click Test | Kohiclicktest.net"></meta>
                <meta name="description" content="Kohi click test is the best technique to increase your clicking speed in a given time frame. Click here to take a kohi click test and improve your CPS speed. "></meta>


                <meta property="og:type" content="website"></meta>
                <meta property="og:url" content=""></meta>
                <meta property="og:title" content="Kohi Click Test | Kohiclicktest.net"></meta>
                <meta property="og:description" content="Kohi click test is the best technique to increase your clicking speed in a given time frame. Click here to take a kohi click test and improve your CPS speed. "></meta>
                <meta property="og:image" content=""></meta>


                <meta property="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:url" content=""></meta>
                <meta property="twitter:title" content="Kohi Click Test | Kohiclicktest.net"></meta>
                <meta property="twitter:description" content="Kohi click test is the best technique to increase your clicking speed in a given time frame. Click here to take a kohi click test and improve your CPS speed. "></meta>
                <meta property="twitter:image" content=""></meta>
                <link rel="canonical" href="" />
                <meta name="robots" content="index, follow"></meta>
                <meta name="revisit-after" content="1 days"></meta>
                <meta name="author" content=""></meta>

            </MetaTags>
           <Navbar/>

            <div style={{ marginTop: "100px" }}>
                <h1 className="text-center fw-bold text-white">KOHI CLICK TEST <img src={kohiemoji} alt="Kohi Click Test Photo" width="45px" height="45px"></img></h1>
                <p className="text-white text-center fw-bold" style={{ fontSize: "19.5px" }}>This test shows your current result and the best one! <br />Take the <b>kohi click test</b>, prove to your friends that <br /> you are the fastest! ✌️ </p>
            </div>

            <div id="ClickCount" className="d-flex justify-content-center">{counter}</div>
            <div id="ClickResultInfo" className="text-center">
                <span id="finalMaxScore">YOUR MAX SCORE: {maxScore} ({cps} CPS)</span>
                <span id="timerSpan" style={{ display: "none" }}>👊 Time to the end: {timeDuration} sec.</span>


            </div>



            <div className="d-flex justify-content-center w-100 my-5">
                <button id="ClickButton" onClick={counterFun}> Click Here!</button>
                <input type="hidden" value={hiddenInpVal} id="hiddenInpVal" />
            </div>

            <div className="d-flex justify-content-center my-5">
                <button id="ClearClicksResult" className="mx-1" onClick={restartFun}>Restart</button>
                <button id="restartMaxScore" className="mx-1" onClick={resetMaxScore}>Reset Max Score</button>
                <button id="StopClicks" className="mx-1">{intVStopper}</button>
            </div>

            <div className="py-5 ">
                <div className="container my-5">
                <h2 className="text-center text-white mb-3 fw-bold">What is the Kohi click test?</h2>
                    <p className="text-center text-white mb-0 lh-lg wordSpacing" >The Kohi Click Test is a method of clicking test in which players can increase their clicking speed in a given timeframe. It is also known as the Minecraft server.</p>
                    <p className="text-center text-white  mb-0 lh-lg wordSpacing"> Players use the Kohi Click test method to improve their clicking speed to make the highest score in the game. It is the same as the other programs of click test, but the only difference is that it is featured on a Minecraft Server.</p>
                </div>
                    

                </div>
            <div className="container py-5 mt-5 text-white">
            <h2  className=" mb-3 text-center">How to Kohi Click?</h2>
            <p className="text-center mb-5">Follow the below steps to Kohi Click and become a pro:-</p>

                  <div className="row">
                      <div className="col-md-3 p-5" style={{position:"relative"}}>
                          <figure style={{position:"absolute" ,top:"25px" ,left:"25px"}}>
                              <img src={stepOne} alt="step" width="100%" height="100%"/>
                          </figure>
                          <div className="stepBorder borderReadius10 stepCard h-100 px-3 py-4 d-flex align-items-center bg-black">
                             <p className="text-center mb-0 lh-lg">Initiate the game by pressing the Click Here button.</p>
                          </div>
                      </div>
                      <div className="col-md-3 p-5" style={{position:"relative"}}>
                      <figure style={{position:"absolute" ,top:"25px" ,left:"25px"}}>
                              <img src={stepTwo} alt="step" width="100%" height="100%" />
                          </figure>
                      <div className="stepBorder borderReadius10 stepCard h-100 px-3 py-4 d-flex align-items-center bg-black">
                          <p className="text-center mb-0 lh-lg">The timer has a limit of 10 seconds.</p>
                          </div>
                      </div>
                      <div className="col-md-3 p-5" style={{position:"relative"}}>
                      <figure style={{position:"absolute" ,top:"25px" ,left:"25px"}}>
                              <img src={stepThree} alt="step" width="100%" height="100%" />
                          </figure>
                      <div className="stepBorder borderReadius10 stepCard h-100 px-3 py-4 d-flex align-items-center bg-black">

                          <p className="text-center mb-0 lh-lg">Keep clicking the button quickly till the timer reaches 10 seconds.</p>
                          </div>
                      </div>
                      <div className="col-md-3 p-5" style={{position:"relative"}}>
                      <figure style={{position:"absolute" ,top:"25px" ,left:"25px"}}>
                              <img src={stepFour} alt="step" width="100%" height="100%" />
                          </figure>
                      <div className="stepBorder borderReadius10 stepCard h-100 px-3 py-4 d-flex align-items-center bg-black">

                          <p className="text-center mb-0 lh-lg">Once done, your score will be displayed on the screen.</p>
                          </div>

                      </div>

                  </div>

              
                {/* <div className="text-white">
                    <h2  className=" mb-5">How to Kohi Click?</h2>
                    <p>Follow the below steps to Kohi Click and become a pro:-</p>
                    <ol className="text-white ps-0">
                        <li className="lh-lg">Initiate the game by pressing the Click Here button.</li>
                        <li className="lh-lg">The timer has a limit of 10 seconds.</li>
                        <li className="lh-lg">Keep clicking the button quickly till the timer reaches 10 seconds.</li>
                        <li className="lh-lg">Once done, your score will be displayed on the screen.</li>

                    </ol>
                </div> */}
                <div className="row mt-5 text-white">
                    <h2 className="text-center mb-3 lh-lg">Difference between Kohi click test and regular CPS test </h2>
                    <p className="text-center mb-3 lh-lg">It is a strategy that most of us do on an everyday premise while getting to the PC. The method is <br/> beneficial for individuals that need to play the game as a sit back instead of contending.</p>
                    <div className="col-md-6 my-5">
                        <div className="boxshadow h-100 p-3 d-flex flex-column align-items-center borderReadius10 card ">
                            <img src={first} alt="pic" width="100px" height="100px"></img>
                            <h3>Regular Clicking</h3>
                            <p className="text-center mt-3 text-white lh-lg">Utilizing the technique, players can score a normal CPS of 4–6 clicks for each second. However, individuals who have dominated the method can stretch out their score to even 8 CPS. The lone significant downside is that players can't score higher CPS utilizing the strategy. It is ideal for gamers to change to different methods for clicking for a higher CPS rate</p>
                        </div>

                    </div>
                    <div className="col-md-6 my-5">
                        <div className="boxshadow p-3 d-flex flex-column align-items-center borderReadius10 card  ">
                            <img src={sec} alt="pic" width="100px" height="100px"></img>
                            <h3>The kohi click test </h3>
                            
                            <p className="text-center mt-3 text-white lh-lg">The kohi click test is a strategy to decide the proportion of snaps every second. It is famous for hardcore faction’s game modes. A few snap speed programs permit clients to recognize their mouse-clicking speed over a given period. The Kohi Click Test is utilized by the individuals who play Minecraft. Players step through the exam to improve their mouse-clicking pace to perform better in the game.</p>
                        </div>

                    </div>
                    




                </div>

                <div className="text-white my-5">
                    <h2 className="mb-4 " >Features of Kohi click test</h2>
                  
                    <ul className="text-white ps-0">
                        <li className="lh-lg">It is free to download and use with unlimited access. There are no memberships, registration, or payments for downloading or launching this auto mouse clicker.</li>
                        <li className="lh-lg">It is easy to operate for beginners and is 100% safe and secure.</li>
                        <li className="lh-lg">It is constantly developed. All errors are corrected immediately, all proposals, comments are considered.</li>
                        <li className="lh-lg">Users can readily use it on any device, mobile phone, laptop, and computer. It allows websites and pages to render on all devices and screen sizes</li>
                        <li className="lh-lg">We also offer you benefits like social sharing. You can easily share your score from our website on social media. Moreover, you can invite your friends through social sharing to beat your highest score.</li>
                    </ul>
                </div>

                <div className="my-5">
                    <h2 className=" text-white mb-3">Why Kohi Click Test?</h2>
                    <p className=" text-white mb-0 lh-lg">Like other clicking tests, the Kohi click test allows users to identify their mouse-clicking speed over a given time frame. Still, it has its importance because it is featured on a Minecraft server known as Kohi. </p>
                    <p className=" text-white  mb-0 lh-lg">The Kohi server is popular for Hard Core Factions game modes. While Kohi is an offshoot of MineHG, the latter is a spin-off of Minecraft PVP (MCPVP). Therefore, Kohi Click Test is used by the players who play Minecraft to improve their mouse-clicking speed to perform better in the game.</p>

                </div>

                 <div className="my-5"> 
                    <h2 className=" text-white mb-3">How Can I Get Better at kohi Clicking? </h2>
                    <p className=" text-white mb-0 lh-lg">The best and efficient way to get better at Kohi Clicking is by regular practice. Take Kohi tests regularly and work on improving your score to get better.</p>
                 </div>

                 <div className="my-5">
                    <h2 className=" text-white mb-3">How fast can you click on the Kohi test?</h2>
                    <p className=" text-white mb-0 lh-lg">The average clicking speed in Kohi is usually 3 to 6 clicks per second, but using different clicking techniques, you can achieve the mouse click speed of as higher as 8 to 10 clicks per second.</p>
                 </div>

                 </div>
                 <div className="my-5 bg-banner">
                     <div className="container py-5">
                     <h2 className=" text-white mb-3">Why clicking speed is essential?</h2>
                    <p className=" text-white mb-0 lh-lg">The click speed test is essential for gamers. The pace at which they can click a mouse enables them to amplify their performance. Data from independent forums indicate that players with 6-7 clicks per second are good. Some state that when fighting in Minecraft PvP Combat, players must swing their swords at least ten times in a second, which translates to 10 clicks per second. The faster the clicking, the more chances that a player can hit the enemy.</p>
                    <p className=" text-white mb-0 lh-lg">You can use Click Speed Test to check the speed of your internet connection, webpage, and other types of internet. The purpose of using this software for checking internet speed is to ensure that it will help you to determine if your ISP can provide you with the pace that they promised you.</p>
                     </div>
                    

                 </div>
                <div className="container">
                 <div className="row text-white my-5">
                     <h2 className="text-center">Different clicking method</h2>
                  <div className="col-md-6   mt-5 mb-4">
                      <h3>Drag Clicking</h3>
                      <p className="lh-lg">Drag clicking is one of the fastest clicking techniques to register more clicks in less time. The click speed varies from 25 to 100 cps. To master the drag clicking technique, you need to drag your finger from the top to the edge of the mouse button so that when you move your finger, it stimulates mouse clicks.</p>
                      <p className="lh-lg">The point of the test is to drag the two-part object onto the one-part object as quickly as possible. The test measures the number of clicks you can make while dragging the Drag to the Drag Target in a second. Consistent practice of drag clicking will boost your clicking ability and improve your gaming skills.</p>
                  </div>
                  <div className="col-md-6  mt-5 mb-4 ">
                      <figure className="d-flex justify-content-center w-100">
                          <img src={Drag} alt="dragImg" className="otherToolImg" />
                      </figure>
                  </div>
                  <div className="col-md-6 mt-5  mb-4">

                  <figure className="d-flex justify-content-center w-100">
                          <img src={Jitter} alt="dragImg" className="otherToolImg"/>
                      </figure>
                  </div>

                  <div className="col-md-6  mt-5 mb-4">
                      <h3>Jitter clicking</h3>
                      <p className="lh-lg">Jitter clicking is a beneficial technique developed for the users to create more clicks quickly on a mouse. In this method, users can click as fast as possible with one finger, usually the index finger. It is about jittering your hand on the mouse as fast as you can.</p>
                      <p className="lh-lg">With jitter clicking, users can achieve a high click rate of 10 to 14 CPS. Fast clicking technique matters, especially in the gaming and typing field. Hence, mastering the Jitter clicking technique will no doubt make you a pro gamer.</p>
                  </div>
                  <div className="col-md-6 mt-5 mb-4">
                  <h3>Butterfly clicking</h3>
                      <p className="lh-lg">Another finest method for enhancing your clicking speed is the Butterfly clicking technique. By using this technique, you can achieve a clock speed of 15 to 25 cps. To click as fast as you can using the butterfly technique, users need to click using two fingers, usually the index and middle finger</p>
                      <p className="lh-lg">The butterfly clicking technique aims at registering maximum clicks by users in a given period, boosting their CPS score and making them efficient gamers</p>
                  </div>
                  <div className="col-md-6 mt-5 mb-4">
                  <figure className="d-flex justify-content-center w-100">
                          <img src={butterFly} alt="dragImg" className="otherToolImg"/>
                      </figure>
                  </div>

                 </div>
                 <div className="text-white my-4 ">
                     <h4 className="">What is the fastest clicking method from the above three methods?</h4>
                     <p className="">Jitter clicking and butterfly clicking are the fastest clicking method.</p>
                 </div>
                 <div className="text-white  my-5">
                     <h2 className="text-white ">Can I use Auto clickers to kohi click? </h2>
                     <p className="">Yes, you can use auto clickers. However, it is not recommended to use them. Some software can easily detect the use of such auto clickers and get your score nullified.</p>
                 </div>
                 <div className="text-white  my-5">
                     <h2 className="text-white ">Is Kohi clicking safe?</h2>
                     <p className="">Yes, it is 100% safe and secure to use. It does not contain any malware or virus. But consistent clicking for hours can cause some health issues.</p>
                 </div>
                 </div>
           

           <Footer/>


        </section>
    )
}
export default KohiClicker;