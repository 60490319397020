import React from 'react';
import './App.css';
import KohiClicker from './components/Kohiclicker';
import {Switch,Route } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Feedback from './components/Feedback';
function App() {
  return (
   
    <Switch>
      <Route exact path="/" component={KohiClicker}/>
      <Route exact path="/about" component={AboutUs}/>
      <Route exact path="/privacy" component={Privacy}/>
      <Route exact path="/terms" component={Terms}/>
      <Route exact path="/feedback" component={Feedback}/>


      

    </Switch>
   

  );
}

export default App;
