import React from 'react'
import logo from '../images/Kohiclicktest.net-new.png';
import { Link } from 'react-router-dom';

const Navbar=()=>{
    return(
        <nav className="navbar navbar-expand-lg navbar-light shadow sticky-top bg-nav">
        <div className="container">
            <Link className="navbar-brand text-white" to="/" ><img src={logo} alt="logo" width="100%" height="40px" className="mainLogo" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link mx-1 text-white fw-bold" href="#">Time</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link mx-1  text-white fw-bold" href="#">Click Counter</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link mx-1  text-white fw-bold " href="#">Spacebar Counter</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link mx-1  text-white fw-bold " href="#">Advance CPS Test</a>
                    </li>
                </ul> */}

            </div>
        </div>
    </nav>
    )
}
export default Navbar