import React from 'react'
import logo from '../images/kohiclicktest.net.png';

import twitterLogo from '../images/Twitter.svg';
import fbLogo from '../images/FB.svg';

import instaLogo from '../images/Insta.svg';
import youLogo from '../images/Youtube.svg';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className="footer py-5">

            <footer className="container mt-5">
                <div className="row d-flex align-items-center">
                    <div className="col-md-3 mb-3 ">
                        <figure className="d-flex justify-content-center mb-0">
                            <Link to="/"><img src={logo} alt="footerLogo" className="mainLogo" width="100%" height="40px" style={{ cursor: "pointer" }} /></Link>
                        </figure>
                    </div>
                    <div className="col-md-7 mb-3 d-flex justify-content-around flex-column ">
                        <div className="row">
                            <div className="col-sm-3 d-flex justify-content-center">
                                <Link style={{ fontSize: "15px", cursor: "pointer" }} to="/about" className="text-white text-bold text-decoration-none fw-bold">About Us</Link>

                            </div>
                            <div className="col-sm-3 d-flex justify-content-center">
                                <Link style={{ fontSize: "15px", cursor: "pointer" }} to="/privacy" className="text-white text-bold text-decoration-none fw-bold">Privacy Policy</Link>

                            </div>
                            <div className="col-sm-3 d-flex justify-content-center">
                                <Link style={{ fontSize: "15px", cursor: "pointer" }} to="/terms" className="text-white text-bold text-decoration-none fw-bold">Term & condition</Link>

                            </div>

                            <div className="col-sm-3 d-flex justify-content-center">
                                <Link style={{ fontSize: "15px", cursor: "pointer" }} to="./feedback" className="text-white text-bold text-decoration-none fw-bold">Feedback</Link>

                            </div>


                        </div>
                    </div>

                    <div className="col-md-2 mb-3">
                        <div className="row">
                            <div className="col-3 d-flex justify-content-center">
                                <div className="rounded-circle text-white  d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}><img src={fbLogo} alt="fbLogo" width="100%" height="100%" /></div>
                            </div>
                            <div className="col-3 d-flex justify-content-center">
                                <div className="rounded-circle text-white  d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}><img src={twitterLogo} alt="twitter" width="100%" height="100%" /></div>
                            </div>
                            <div className="col-3 d-flex justify-content-center">
                                <div className="rounded-circle  text-white d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}><img src={instaLogo} alt="" width="100%" height="100%" /></div>

                            </div>
                            <div className="col-3 d-flex justify-content-center">
                                <div className="rounded-circle  text-white d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}><img src={youLogo} alt="" width="100%" height="100%" /></div>

                            </div>


                        </div>


                    </div>

                </div>

            </footer>
        </div>


    )
}

export default Footer;